import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {Product} from '../../../domain/models/product/product';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductService} from '../../../domain/product.service';
import {StoreProfile} from '../../../domain/models/store/store-profile';
import {ImageUtils} from '../../../utils/image.utils';
import {Image} from '../../../domain/models/product/image';
import SwiperCore, {Pagination} from 'swiper/core';
import {Dimension} from '../../../domain/models/product/dimension';
import {FormatUtils} from '../../../utils/format.utils';
import {Campaign} from '../../../domain/models/campaign/campaign';
import {CampaignUtils} from '../../../utils/campaign.utils';
import {CampaignRuleScope} from '../../../domain/models/campaign/rule';
import {StoreService} from '../../../domain/store.service';
import {Store} from '../../../domain/models/store/store';
import {ForegroundPaths} from '../../../app-routing.module';
import {Option} from '../../../domain/models/product/option';
import {MatDialog} from '@angular/material/dialog';
import {SelectOptionDialog} from '../../../dialogs/select-option/select-option.dialog';
import {CartService} from '../../../domain/cart.service';
import {DimensionAvailabilityService} from '../../../domain/dimension-availability.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {DimensionAvailability} from '../../../domain/models/product/dimension-availability';
import {AdjustmentTypeService} from '../../../domain/adjustment-type.service';
import {delay} from '../../../utils/promise.utils';
import {bugsnagStartSpan} from '../../../bugsnag';
import {WeightInputDialog} from '../../../dialogs/weight-input/weight-input.dialog';
import {Gs1Utils} from '../../../utils/gs1.utils';

SwiperCore.use([Pagination]);

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.sass']
})
export class ProductDetailsComponent implements OnInit {
  store: Store | undefined;
  product: Product | undefined;
  profile: StoreProfile | undefined;
  selectedDimension: Dimension | undefined;
  campaigns: Campaign[] = [];
  relatedProducts: Product[] = [];
  price?: string;
  unitPrice?: number;
  volume?: number;
  unitAbbreviation?: string;
  dimensionAvailability: DimensionAvailability[] = [];
  availabilityCount: number | undefined;
  currencyCode: string | undefined;
  cultureName: string;

  showFullDescription: boolean = false;
  isStock: boolean = false;
  isBusy: boolean = false;
  isUnitPricing: boolean = false;
  isDirectToPayment = false;
  quantity = 1;
  showQuantitySelector = false;

  constructor(@Inject(LOCALE_ID) localeId: string,
              private router: Router,
              private route: ActivatedRoute,
              private storeService: StoreService,
              private productService: ProductService,
              private cartService: CartService,
              private dialogService: MatDialog,
              private dimensionAvailabilityService: DimensionAvailabilityService,
              private toastr: ToastrService,
              private adjustmentService: AdjustmentTypeService,
              private translateService: TranslateService,
  ) {
    this.cultureName = localeId;
  }

  async ngOnInit() {
    const storeHandle = this.route.parent!.firstChild!.snapshot.paramMap.get('id')!;
    const productHandle = this.route.snapshot.paramMap.get('productId')!;
    const dimensionId = this.route.snapshot.paramMap.get('dimensionId')!;
    this.isDirectToPayment = this.route.snapshot.queryParamMap.get('direct-payment') == 'true';
    this.store = await this.storeService.getStore(storeHandle);
    this.profile = this.store.storeProfile;
    this.storeService.showQuantitySelector(storeHandle).then(value => this.showQuantitySelector = value);

    try {
      this.product = await this.productService.getProductByHandle(storeHandle, productHandle);
    } catch (error) {
      if (error.status == 404) {
        const errorMessage = await this.translateService.get('PRODUCTDETAILS.productNotFound').toPromise();
        this.toastr.warning(errorMessage, undefined, {timeOut: 3000, easeTime: 100, positionClass: 'toast-bottom-center'});
        await this.router.navigate(ForegroundPaths.empty());
        return;
      }
      await this.router.navigate(ForegroundPaths.empty());
      throw error;
    }

    this.campaigns = CampaignUtils.campaignsForProduct(this.store, this.product.id);

    this.product?.options?.sort((a, b) => a.sortIndex - b.sortIndex);
    this.dimensionAvailability = await this.dimensionAvailabilityService.getStock(storeHandle, this.product.id);
    if (this.dimensionAvailability[0].trackStock) {
      this.isStock = true;
    }
    this.availabilityCount = this.dimensionAvailability[0].count;
    if (dimensionId == undefined) {
      this.selectedDimension = this.product.dimensions[0];
    } else {
      this.selectedDimension = this.product.dimensions.find(t => t.id == dimensionId);
    }
    if (this.product.relatedProducts != null) {
      for (let relatedProductHandle of this.product.relatedProducts) {
        this.relatedProducts.push(await this.productService.getProductByHandle(storeHandle, relatedProductHandle));
      }
    }

    this.currencyCode = this.store.currencyCode;
    await this.setPrice();
  }

  private async setPrice() {
    if (this.product != null) {
      this.unitPrice = this.product!.dimensions[0].prices[0].amountPerProductUnit / 100;

      let abbreviation = this.product!.dimensions[0].prices[0].unitOfMeasureAbbreviation;

      if (abbreviation != null) {
        this.unitAbbreviation = this.product!.dimensions[0].prices[0].unitOfMeasureAbbreviation.toString();
        this.volume = this.product!.dimensions[0].prices[0].volume;
      }

      if (this.unitPrice && this.unitAbbreviation && this.volume != null) {
        this.isUnitPricing = true;
      }

      this.price = FormatUtils.toFriendlyPrice(this.product!.dimensions[0].prices[0].amount);

      let storedAdjustmentTypeId = this.adjustmentService.getStoredTypeId(this.store!.handle);
      if (storedAdjustmentTypeId != null) {
        const price = this.product!.dimensions[0].prices.find(p => p.adjustmentType.id == storedAdjustmentTypeId);
        this.price = FormatUtils.toFriendlyPrice(price!.amount);
      } else {
        this.price = FormatUtils.toFriendlyPrice(this.product!.dimensions[0].prices[0].amount);
      }
    }
  }

  getImage(image: Image, number: number): string {
    return ImageUtils.getImageUrl(image, number)!;
  }

  getFallBackImage(): string {
    if (this.store?.storeProfile?.logoImage) {
      return this.store.storeProfile.logoImage.url;
    }

    return '/assets/images/noimage.png';
  }

  isOverflown(element: Element) {
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
  }

  toFriendlyPrice(price: number): string {
    return FormatUtils.toFriendlyPrice(price);
  }

  getCheapestPrice(product: Product, campaigns: Campaign[]): number {
    return CampaignUtils.getCheapestPrice(product, campaigns);
  }

  hasProductCampaign(campaigns: Campaign[]): boolean {
    return campaigns.filter(c => c.rule.scope === CampaignRuleScope.Product).length > 0;
  }

  async addToCart(storeHandle: string, product: Product, dimension?: Dimension | undefined, quantity?: number | undefined): Promise<any> {
    const span = bugsnagStartSpan('product-details:addToCart');
    this.isBusy = true;

    let generatedScannedBarcode: string | undefined;
    if (product.isWeight && Gs1Utils.isValidProductBarcode(dimension?.barcode)) {
      const weight = await this.dialogService.open(WeightInputDialog, {
        data: {unit: 'grams'},
      }).afterClosed().toPromise();
      if (!weight) {
        this.isBusy = false;
        span?.end();
        return;
      }
      try {
        generatedScannedBarcode = Gs1Utils.generateWeightBarcode(dimension!.barcode, weight);
      } catch (error) {
        this.toastr.error(error, undefined, {positionClass: 'toast-bottom-center'});
        this.isBusy = false;
        span?.end();
        return;
      }
    }

    const isShipmentDefault = product.fulfillmentOptions?.find(option => option.type === 'shipment')?.default;

    if (isShipmentDefault != undefined && !isShipmentDefault && this.store) {
      await this.router.navigate(ForegroundPaths.empty());
      await delay(80);
      await this.cartService.add(this.store.handle, product, dimension?.id, quantity, undefined, generatedScannedBarcode);
      this.isBusy = false;
      span?.end();
      return;
    }

    const itemCount = (quantity ?? 1) + await this.cartService.getItemCount(storeHandle, product, product.dimensions[0]);
    if (this.store != null && this.isStock && this.dimensionAvailability[0].count >= itemCount || this.store != null && !this.isStock) {
      await this.router.navigate(ForegroundPaths.empty());
      await delay(80);
      await this.cartService.add(this.store.handle, product, dimension?.id, quantity, undefined, generatedScannedBarcode);
    } else {
      const message = await this.translateService.get('PRODUCTDETAILS.noStock').toPromise();
      this.toastr.error(message, undefined, {timeOut: 3000, easeTime: 100, positionClass: 'toast-bottom-center'});
    }
    this.isBusy = false;
    span?.end();
  }

  async addToCartAndGoToPayment(storeHandle: string, product: Product, dimension?: Dimension | undefined): Promise<void> {
    await this.addToCart(storeHandle, product, dimension, this.quantity);
    await this.router.navigate(ForegroundPaths.cartPayment());
  }

  async openProduct(productHandle: string) {
    await this.router.navigate(ForegroundPaths.empty());
    await this.router.navigate(ForegroundPaths.product(productHandle));
  }

  getOptionValue(selectedDimension: Dimension, i: number): string {
    switch (i) {
      case 0:
        return selectedDimension.productOption1Value;
      case 1:
        return selectedDimension.productOption2Value;
      case 2:
        return selectedDimension.productOption3Value;
      default:
        return selectedDimension.productOption1Value;
    }
  }

  selectDimension(option: Option, i: number) {
    this.dialogService
      .open(SelectOptionDialog, {
        width: '250px',
        data: option
      })
      .afterClosed()
      .subscribe(result => {
        if (result != null) {
          let selected = this.product?.dimensions.find((dim) => {
            let current = this.selectedDimension;
            for (let j = 0; j < 3; j++) {
              if (i === j && this.getOptionValue(dim, j) != result) {
                return false;
              } else if (current != null && i != j
                && this.getOptionValue(current, j) != this.getOptionValue(dim, j)) {
                return false;
              }
            }
            return true;
          });
          if (selected != null) {
            this.selectedDimension = selected;
          }
        }
      });
  }
}
