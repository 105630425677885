<div style="display: flex; align-items: center;">
  <button [disabled]="!canDecrement"
          (click)="onDecrement.emit()"
          class="quantity-incrementer_button">
    <span style="font-size: 16px; margin-top: -2px">-</span>
  </button>

  <span style="margin: 0 10px;">
  <ng-content></ng-content>
  </span>

  <button [disabled]="!canIncrement"
          (click)="onIncrement.emit()"
          class="quantity-incrementer_button">
    <span style="font-size: 16px; margin-top: -2px">+</span>
  </button>
</div>
