import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-quantity-incrementor',
  templateUrl: './quantity-incrementor.component.html',
  styleUrls: ['./quantity-incrementor.component.sass']
})
export class QuantityIncrementorComponent {
  @Input() canIncrement = true;
  @Input() canDecrement = true;
  @Output() onIncrement = new EventEmitter<number>();
  @Output() onDecrement = new EventEmitter<number>();
}
