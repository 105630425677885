import {Component, EventEmitter, Input, Output} from '@angular/core';
import {StoreProfile} from '../../domain/models/store/store-profile';

@Component({
  selector: 'app-quantity-selector',
  templateUrl: './quantity-selector.component.html',
  styleUrls: ['./quantity-selector.component.sass'],
})
export class QuantitySelectorComponent {

  @Input() isBusy: boolean = false;
  @Input() profile: StoreProfile | undefined;
  @Input() quantity: number = 0;

  @Output() increase = new EventEmitter();
  @Output() decrease = new EventEmitter();
  @Output() quantitySubmitted = new EventEmitter<number>();

  constructor() {
  }

  onSubmit(input: string) {
    const quantity = Number.parseInt(input);
    if (quantity >= 0) {
      this.quantitySubmitted.emit(quantity);
    } else {
      this.quantitySubmitted.emit(this.quantity = 1);
    }
  }
}
